(function () {
  'use strict';

  angular
    .module('pmf')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('pmf', {
        url: '/pmf?location',
		params:{
			location:{value:'', squash:true}
		},
		views:{
			navigation: {
			   templateUrl: 'navigation/navigation.tpl.html',
			   controller: 'NavigationCtrl',
			   controllerAs: 'navigation'
			},
			alerts: {
			   templateUrl: 'alerts/alerts.tpl.html',
			   controller: 'AlertsCtrl',
			   controllerAs: 'alerts'
			},
			main:{
				templateUrl: 'pmf/pmf.tpl.html',
				controller: 'PmfCtrl',
				controllerAs: 'pmf'
			},
			footer: {
			   templateUrl: 'footer/footer.tpl.html',
			   controller: 'FooterCtrl',
			   controllerAs: 'footer'
			 }
		}
        
      });
  }
}());
